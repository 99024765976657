import { useEffect, useRef, useState } from "react";
import { BsTags } from "react-icons/bs";
import { FaRegCalendarCheck } from "react-icons/fa";
import { GoLocation } from "react-icons/go";
import { HiOutlineUserCircle, HiOutlineUserGroup } from "react-icons/hi2";
import { IoMdTime } from "react-icons/io";
import { IoCloseOutline } from "react-icons/io5";
import Modal from "react-modal";
import { animated, useSpring } from "react-spring";
import { toast } from "react-toastify";
import CustomButton from "../../../components/CustomButton";
import CustomDropdown from "../../../components/CustomDropDown";
import CustomInput from "../../../components/CustomInput";
import CustomTextArea from "../../../components/CustomTextArea";
import { customStyles } from "../../../components/Shared";
import api from "../../../service/api";
import { getProfessionalId } from "../../../service/token.service";
import { adicionarUmaHora } from "../../../utils/AddHour";
import AvailableSlots from "../../AvailableSlots";
import CreateProfessional from "../../CreateProfessional";
import CreateUser from "../../Patient/Create";
import { deburr } from "lodash";
import { IoMdAdd } from "react-icons/io";

import {
  AddressWrapper,
  AppointmentWrapperColumn,
  ButtonFreeHoursWrapper,
  ContentWrapper,
  FlexWrapper,
  FooterWrapper,
  HeaderTitle,
  HeaderWrapper,
  InfoWrapper,
  ModalWrapper,
  ObservationsWrapper,
  SeparatorBottom,
  ExamsWrapper,
} from "./style";
import { ExamsResponse } from "../../../pages/Home/Interfaces/Exams";
import CustomDropdownMultiselect from "../../../components/CustomDropDownMultiselect";
import { ModalError } from "../../../components/ModalError";

interface CreateEventProps {
  closeModal: () => void;
  initialDate?: string;
}

interface NextAvailableSlot {
  month: string;
  days: {
    Domingo?: {
      date: string;
      available_slots: string[];
    }[];
    Segunda?: {
      date: string;
      available_slots: string[];
    }[];
    Terça?: {
      date: string;
      available_slots: string[];
    }[];
    Quarta?: {
      date: string;
      available_slots: string[];
    }[];
    Quinta?: {
      date: string;
      available_slots: string[];
    }[];
    Sexta?: {
      date: string;
      available_slots: string[];
    }[];
    Sabado?: {
      date: string;
      available_slots: string[];
    }[];
  };
}

export const CreateEvent = (props: CreateEventProps) => {
  const timing: string[] = ["1 hora", "30 min"];
  props?.initialDate
    ? new Date(props?.initialDate.slice(0, -1) + "+03:00")
        .toISOString()
        .slice(0, 10)
    : new Date().toISOString().slice(0, 10);
  const actualDate = props?.initialDate
    ? new Date(props?.initialDate.slice(0, -1) + "+03:00")
    : new Date();
  const actualTime = props?.initialDate
    ? new Date(props?.initialDate.slice(0, -1) + "+03:00")
        .toISOString()
        .slice(11, 16)
    : new Date().toISOString().slice(11, 16);
  actualDate.setHours(parseInt(actualTime.split(":")[0]));
  actualDate.setMinutes(parseInt(actualTime.split(":")[1]));
  const endTime = new Date(props?.initialDate || actualDate);
  endTime.setMinutes(endTime.getMinutes() + 15);

  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);

  const [formData, setFormData] = useState<any>({
    start_time: actualDate.toISOString(),
    start_date: props?.initialDate
      ? new Date(props?.initialDate.slice(0, -1) + "+03:00")
          .toISOString()
          .slice(0, 10)
      : new Date().toISOString().slice(0, 10),
    end_time: adicionarUmaHora(actualTime),
    duration: timing[0],
    selectedExams: [],
    time: actualTime,
    client: null,
    professional: null,
    establishment: null,
  });

  const [professionals, setProfessionals] = useState<any>(null);
  const [nextAvailableSlots, setNextAvailableSlots] = useState<
    NextAvailableSlot[]
  >([]);
  const [clients, setClients] = useState<any>(null);
  const clientsBackup = useRef<any>(null);
  const [estabilishments, setEstabilishments] = useState<any>(null);
  const [exams, setExams] = useState<ExamsResponse[]>([]);
  const [tags, setTags] = useState<any>([]);
  const [clientTags, setClientTags] = useState<any[]>([]);
  const [isFreeHoursModalOpen, setIsFreeHoursModalOpen] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorList, setErrorList] = useState<string[]>([]);

  const handleSearch = (value: string) => {
    const normalizedSearch = deburr(value).toLowerCase();
    const clientesFiltrados = clientsBackup.current.filter((cliente: any) =>
      deburr(cliente.name).toLowerCase().includes(normalizedSearch)
    );

    setClients(clientesFiltrados);
  };

  const fetchClients = () => {
    api.get(`/clients/`).then((response) => {
      setClients(response.data);
      clientsBackup.current = response.data;
    });
  };

  const fetchFilterClients = (id: string) => {
    api.get(`/professional/${id}/clients/`).then((response) => {
      setClients(response.data);
    });
  };

  const fetchProfessionals = () => {
    const professionalId = getProfessionalId();
    if (professionalId) {
      api.get(`/professionals/${professionalId}/`).then((response) => {
        setProfessionals([response.data]);
      });
    } else {
      api.get(`/professionals/`).then((response) => {
        setProfessionals(response.data);
      });
    }
  };

  const fetchFreeHours = (url: string) => {
    api.get(url).then((response) => {
      setNextAvailableSlots(response.data);
    });
  };

  const fetchClientTags = () => {
    api.get("/tags/").then((resp) => {
      setClientTags(resp.data);
    });
  };

  useEffect(() => {
    api.get(`/establishments/`).then((response) => {
      setEstabilishments(response.data);
    });
    fetchProfessionals();
    fetchClients();
    fetchClientTags();
  }, []);

  const handleTimeChange = (value: string) => {
    try {
      const timeParts = value.split(":");
      if (timeParts.length !== 2) {
        throw new Error("Formato de hora inválido.");
      }

      const hour = parseInt(timeParts[0], 10);
      const minutes = parseInt(timeParts[1], 10);

      if (
        isNaN(hour) ||
        isNaN(minutes) ||
        hour < 0 ||
        hour > 23 ||
        minutes < 0 ||
        minutes > 59
      ) {
        throw new Error("Hora ou minutos fora do intervalo permitido.");
      }

      const date = new Date(formData.start_date + "T00:00:00");
      date.setHours(hour, minutes, 0, 0);

      setFormData((prevFormData: any) => ({
        ...prevFormData,
        ["time"]: value,
        start_time: date.toISOString(),
        end_time: adicionarUmaHora(value),
      }));
    } catch (error) {
      console.error("Erro ao processar a hora: ", error);
    }
  };

  const handleInputChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    if (name === "start_date") {
      let date = new Date();
      if (value != "") {
        date = new Date(value + "T00:00:00");
        date.setHours(actualDate.getHours());
        date.setMinutes(actualDate.getMinutes());
      }

      setFormData((prevFormData: any) => ({
        ...prevFormData,
        [name]: value,
        start_time: date?.toISOString() || "",
        time: null,
      }));
    } else {
      setFormData((prevFormData: any) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  function validarDatas(dataInicio: string, dataFinal: string) {
    const inicio = new Date(dataInicio);
    const final = new Date(dataFinal);
    if (final <= inicio) {
      toast.error(
        "Horários inválidos. horário final menor ou igual que o horário inicial"
      );
      return false;
    }
    const diferenca = final.getTime() - inicio.getTime();
    const diferencaMinutos = Math.abs(diferenca / (1000 * 60));
    if (diferencaMinutos < 30) {
      toast.error("Não é possível marcar consultas com duração até 30 minutos");
      return false;
    }
    return true;
  }

  function calcularEndDate(start_time: string, end_time: string) {
    const startDate = new Date(start_time.slice(0, -1) + "+03:00");
    const startHour = startDate.getHours();

    const endHour = parseInt(end_time.split(":")[0]);
    const endMinute = parseInt(end_time.split(":")[1]);

    const date = new Date(start_time.slice(0, -1) + "+03:00");
    date.setHours(endHour);
    date.setMinutes(endMinute);

    if (startHour >= 23) {
      date.setDate(date.getDate() + 1);
    }

    return date.toISOString();
  }

  const saveAppointment = () => {
    setSaveButtonDisabled(true);
   
    const formDataToSend = new FormData();
    if (
      !validarDatas(
        formData.start_time,
        calcularEndDate(formData.start_time, formData.end_time)
      )
    )
      return;

    formDataToSend.append("start_time", formData.start_time);

    formDataToSend.append(
      "end_time",
      calcularEndDate(formData.start_time, formData.end_time)
    );
    formDataToSend.append("duration", formData.duration);
    formDataToSend.append("observation", formData.observation || "");
    formDataToSend.append("patient", formData.patient?.id);
    formDataToSend.append("professional", formData.professional?.id);
    formDataToSend.append("establishment", formData.establishment?.id);
    formDataToSend.append("service", formData.service?.id);
    formDataToSend.append("attachments", formData.attachments);

    if (formData.selectedExams?.length)
      formDataToSend.append(
        "exams",
        formData.selectedExams.map((exam: any) => exam.id)
      );

    api
      .post(`/appointments/`, formDataToSend)
      .then((response) => {
        toast.success("Agendamento criado com sucesso!");
        props.closeModal();
      })
      .catch((error: any) => {
        setErrorList(error.response.data);
        setOpenErrorModal(true);
      })
      .finally(() => {
        setSaveButtonDisabled(false);
      });
  };

  const calculateEndDate = (duration: string) => {
    const date = new Date(formData.start_time);
    if (duration.includes("min")) {
      date.setMinutes(date.getMinutes() + parseInt(duration.split(" ")[0]));
    }
    if (duration.includes("h"))
      date.setHours(date.getHours() + parseInt(duration.split(" ")[0]));
    return date.toISOString();
  };

  const handleDuration = (duration: string) => {
    setFormData({
      ...formData,
      duration,
      end_time: calculateEndDate(duration),
    });
  };

  const getExamsByEstablishments = async (value: any) => {
    const examsResponse = await api.get<ExamsResponse[]>(
      `/establishments/exams/?establishment=${value.id}`
    );

    setExams(examsResponse.data);
  };

  const handleDropdownSelected = (key: any, value: any) => {
    setFormData({ ...formData, [key]: value });
  };

  const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false);
  const [isCreateProfessionalModalOpen, setIsCreateProfessionalModalOpen] =
    useState(false);

  const toggleCreateUserModal = () => {
    setIsCreateUserModalOpen(!isCreateUserModalOpen);
  };

  const toggleFreeHoursModal = () => {
    setIsFreeHoursModalOpen(!isFreeHoursModalOpen);
  };

  const toggleCreateProfessionalModal = () => {
    setIsCreateProfessionalModalOpen(!isCreateProfessionalModalOpen);
  };

  const fade = useSpring({
    from: { opacity: 0 },
    to: { opacity: isCreateUserModalOpen ? 1 : 0 },
  });

  const fadeProfessional = useSpring({
    from: { opacity: 0 },
    to: { opacity: isCreateProfessionalModalOpen ? 1 : 0 },
  });

  const fadeFreeHours = useSpring({
    from: { opacity: 0 },
    to: { opacity: isFreeHoursModalOpen ? 1 : 0 },
  });

  return (
    <>
      <ModalError
        setShowModal={setOpenErrorModal}
        showModal={openErrorModal}
        errorList={errorList}
        title="Houve uma falha na criação do compromisso"
      />
      <Modal
        isOpen={isFreeHoursModalOpen}
        onRequestClose={toggleFreeHoursModal}
        shouldCloseOnOverlayClick={false}
        style={customStyles}
      >
        <animated.div style={fadeFreeHours}>
          <AvailableSlots
            closeModal={toggleFreeHoursModal}
            nextAvailableSlots={nextAvailableSlots}
            setFormData={setFormData}
            setIsFreeHoursModalOpen={setIsFreeHoursModalOpen}
          />
        </animated.div>
      </Modal>
      <Modal
        isOpen={isCreateUserModalOpen}
        onRequestClose={toggleCreateUserModal}
        shouldCloseOnOverlayClick={false}
        style={customStyles}
      >
        <animated.div style={fade}>
          <CreateUser
            isBasicForm={true}
            closeModal={toggleCreateUserModal}
            updateClients={fetchClients}
          />
        </animated.div>
      </Modal>
      <Modal
        isOpen={isCreateProfessionalModalOpen}
        onRequestClose={toggleCreateProfessionalModal}
        shouldCloseOnOverlayClick={false}
        style={customStyles}
      >
        <animated.div style={fadeProfessional}>
          <CreateProfessional
            closeModal={toggleCreateProfessionalModal}
            updateClients={fetchClients}
          />
        </animated.div>
      </Modal>
      <ModalWrapper>
        <HeaderWrapper>
          <HeaderTitle>Criação de Compromisso</HeaderTitle>
          <IoCloseOutline
            size={22}
            onClick={props.closeModal}
            color="#919EAB"
            cursor={"pointer"}
          />
        </HeaderWrapper>
        <ContentWrapper>
          <InfoWrapper>
            <FlexWrapper>
              <CustomDropdown
                label="Profissional"
                setSelectedOption={(value: any) => {
                  api
                    .get(`/services/?professional_id=${value.id || ""}`)
                    .then((response) => {
                      const dataArray = response.data;
                      setTags(dataArray);
                    });

                  fetchFreeHours(`/next-available-slots/${value.id}/`);
                  handleDropdownSelected("professional", value);
                  fetchFilterClients(value.id);
                }}
                selectedOption={
                  formData.professional && formData.professional.name
                }
                options={professionals}
                allowCreate={false}
                onCreate={toggleCreateProfessionalModal}
                createLabel="Cadastrar profissional"
                leftIcon={
                  <HiOutlineUserCircle
                    size={16}
                    color="var(--primary-icon-color)"
                  />
                }
              />
              <CustomDropdown
                searchInput
                label="Paciente"
                onSearchInputChange={(value: string) => {
                  handleSearch(value);
                }}
                setSelectedOption={(value) =>
                  handleDropdownSelected("patient", value)
                }
                selectedOption={formData.patient && formData.patient.name}
                options={clients}
                allowCreate={true}
                onCreate={toggleCreateUserModal}
                createLabel="Cadastrar paciente"
                leftIcon={
                  <HiOutlineUserGroup
                    size={16}
                    color="var(--primary-icon-color)"
                  />
                }
              />
            </FlexWrapper>

            <SeparatorBottom />

            <AddressWrapper>
              <CustomDropdown
                selectedOption={
                  formData.establishment && formData.establishment.name
                }
                setSelectedOption={(value) => {
                  handleDropdownSelected("establishment", value);
                  getExamsByEstablishments(value);
                }}
                label="Local"
                options={estabilishments}
                leftIcon={
                  <GoLocation size={16} color="var(--primary-icon-color)" />
                }
              />
              <CustomDropdown
                selectedOption={formData.service && formData.service.name}
                setSelectedOption={(value) => {
                  handleDropdownSelected("service", value);
                }}
                label="Serviço"
                options={tags}
                leftIcon={
                  <BsTags size={16} color="var(--primary-icon-color)" />
                }
              />
            </AddressWrapper>

            <ExamsWrapper>
              <CustomDropdownMultiselect
                selectedOption={""}
                selectAutomatic={false}
                setSelectedOption={(value) => {
                  const selectedExams =
                    formData.selectedExams as ExamsResponse[];
                  const examAlreadySelected = (examSelected: ExamsResponse) => {
                    if (examSelected.id == value.id) return true;

                    return false;
                  };
                  if (!selectedExams.find(examAlreadySelected))
                    handleDropdownSelected("selectedExams", value);
                }}
                label="Exames"
                options={exams}
                leftIcon={<IoMdAdd size={18} color="white" />}
              />
            </ExamsWrapper>

            <SeparatorBottom />
            <AppointmentWrapperColumn>
              <FlexWrapper>
                <CustomInput
                  label="Datas para atendimento"
                  name="start_date"
                  type="date"
                  value={formData.start_date}
                  min={new Date().toISOString().split("T")[0]}
                  // max={nextAvailableSlots?.[nextAvailableSlots.length-1]?.days[] ? new Date(nextAvailableSlots?.[nextAvailableSlots.length-1]?.date).toISOString().split('T')[0] : undefined}
                  onChange={handleInputChange}
                  leftIcon={
                    <FaRegCalendarCheck
                      size={16}
                      color="var(--primary-icon-color)"
                    />
                  }
                />
                <ButtonFreeHoursWrapper>
                  <CustomButton
                    theme="addPrimary"
                    disabled={nextAvailableSlots.length <= 0}
                    onClick={() => setIsFreeHoursModalOpen(true)}
                    style={{ marginBottom: "10px" }}
                  >
                    Ver horários livres
                  </CustomButton>
                </ButtonFreeHoursWrapper>
              </FlexWrapper>
              <FlexWrapper>
                <CustomInput
                  label="Hora Inicio"
                  name="time"
                  value={formData.time}
                  onChange={(e) => handleTimeChange(e.currentTarget.value)}
                  placeholder="06:00"
                  type="time"
                  leftIcon={
                    <IoMdTime size={16} color="var(--primary-icon-color)" />
                  }
                />
                <CustomInput
                  label="Hora Fim"
                  name="end_time"
                  value={formData.end_time}
                  onChange={handleInputChange}
                  placeholder="06:00"
                  type="time"
                  leftIcon={
                    <IoMdTime size={16} color="var(--primary-icon-color)" />
                  }
                />
              </FlexWrapper>
            </AppointmentWrapperColumn>
            <ObservationsWrapper>
              <CustomTextArea
                label="Descrição"
                name="observation"
                onChange={handleInputChange}
                rows={5}
                placeholder="Observações"
              />
            </ObservationsWrapper>
          </InfoWrapper>
          {/* <Separator />

                <AttachmentWrapper>
                    <AttachmentHeader>
                        <AttachmentTitle>
                            Anexos
                            <CustomButton disabled={true} theme="add">Adicionar</CustomButton>
                        </AttachmentTitle>
                    </AttachmentHeader>
                    <AttachmentList>
                        <EmptyAttachment>
                            <RiAttachmentLine size={30} color="#919EAB" />
                            <EmptyAttachamentText>Ainda não há arquivos</EmptyAttachamentText>
                        </EmptyAttachment>
                    </AttachmentList>
                </AttachmentWrapper> */}
        </ContentWrapper>
        <FooterWrapper>
          <CustomButton theme="cancel" onClick={props.closeModal}>
            Cancelar
          </CustomButton>
          <CustomButton
            theme="success"
            onClick={saveAppointment}
            disabled={saveButtonDisabled}
          >
            Salvar
          </CustomButton>
        </FooterWrapper>
      </ModalWrapper>
    </>
  );
};

export default CreateEvent;
