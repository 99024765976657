import styled from "styled-components";

const TagsPaymentType = {
    default: {
        color: '#919EAB',
        backgroundColor: '#F4F6F8',
    },
    paid: {
        color: '#118D57',
        backgroundColor: '#D3FCD2',
    },
    pending: {
        color: '#B76E00',
        backgroundColor: '#FFF5CC',
    }
}

export const CalendarWrapper = styled.div`
    width: 100%;
    height: 97vh;
    padding: 0 0 30px 0;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: 2px;
    }

    .fc-day-disabled {
        background-color: rgba(224, 224, 224, 0.5);
    }

    .fc-day-holiday {
        background-color: rgba(248, 215, 218, 0.5);
        pointer-events: none !important;
    }
`;

export const CalendarEventBlock = styled.div`

.content-event{
    display: flex;
    flex-direction: column;
    padding: 3px;
}

.box-event{
    display: flex;
    flex-direction: row;
    gap: 4px;
}

.box-event span{
    font-size: 12px;
}




.patient-name{
    font-size: 12px;
}

.payment-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
`;

export interface DolarIconProps {
    type: keyof typeof TagsPaymentType;
}


export const DollarIcon = styled.span<DolarIconProps>`
    width: 18px;
    height: 18px;
    color: ${({ type }) => TagsPaymentType[type].color};
    font-size: 10px;
    font-weight: bold;
    border: 1px solid ${({ type }) => TagsPaymentType[type].color};
    background-color: ${({ type }) => TagsPaymentType[type].backgroundColor};
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: box-shadow 0.3s ease;

    &:hover{
        box-shadow: 0 0 2px 2px ${({ type }) => TagsPaymentType[type].color + '55'}; 
    }
`
